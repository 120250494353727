class Modals {
	constructor() {
		this.$modalActive = $('#modal');
	}
	show(targetRef) {
		let $target = null;

		switch(typeof targetRef){
			case 'string':
				$target = $('[data-modal-id="'+targetRef+'"]');
				break;
			case 'object':
				$target = targetRef;
				break;
			default:
				$target = null;
		}

		const $modalContents = $target.clone().contents();

		$modalContents.append('<div class="close">close</div>');

		$modalContents.find('.close').on('click', () => {
			this.close(targetRef);
		});

		this.$modalActive
			.empty()
			.append($modalContents)
			.addClass('active');
	};

	close(modalId) {
		var $triggers = $('[data-modal-target-id="' + modalId + '"]');
		$triggers.removeClass('active');
		this.$modalActive.removeClass('active');
	}

	initModal($modal) {
		var modalId = $modal.attr('data-modal-id');
		var $triggers = $('[data-modal-target-id="' + modalId + '"]');

		$triggers.on('click', (e) => {
			$triggers.addClass('active');
			e.preventDefault();
			this.show($modal);
			return false;
		});
	}

	initModals() {
		const $modals = $('[data-modal-id]');

		$modals.each((index, el) => {
			this.initModal($(el));
		});
	};
}

export default (new Modals);
