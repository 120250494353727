import { Carousel } from 'latte-carousel';
//import Flickity from 'flickity';
//import {} from 'slick-carousel';


export default class Slider {
	constructor(el) {
		let $parentSection = $(el);

		let slides = $parentSection.data('slides');
		let move = $parentSection.data('move');
		slides = slides ? slides.split(',') : [3.2, 2.4, 2.4, 1.5, 1.2];
		move = move ? move.split(',') : [3, 2, 2, 1, 1];

		let $container = $parentSection.find('.swiper-container');
		let $pagination = $parentSection.find('.swiper-pagination');
		let $buttonNext = $parentSection.find('.swiper-button-next');
		let $buttonPrev = $parentSection.find('.swiper-button-prev');
		let swiperOffset = Math.abs(parseInt($parentSection.css('margin-left')));

		var options = {
			count: slides[0],
			move: move[0],
			touch: true,
			mode: "align",
			buttons: false,
			dots: true,
			rewind: false,
			autoplay: 0,
			animation: 500,
			responsive: {
				"0": { count: slides[4], move: move[4], mode: "free"},
				"400": { count: slides[3], move: move[3], mode: "free" },
				"768": { count: slides[2], move: move[2], mode: "free"},
				"992": { count: slides[1], move: move[1], mode: "free"},
				"1159": { count: slides[0], move: move[0], mode: "free"}
			},
		};

		let carousel = new Carousel($container.get(0), options);
		let $dots = $container.find('.latte-dots');
		setButtons(carousel, $buttonNext, $buttonPrev, $dots);

		$(window).on('resize', () => {
			carousel.trigger('update');
			setButtons(carousel, $buttonNext, $buttonPrev, $dots);
		});

		carousel.on('moved', () => {
			setButtons(carousel, $buttonNext, $buttonPrev, $dots);
		});

		$parentSection.find('.swiper-button-next').on('click', () => {
			carousel.trigger("next");
		});

		$parentSection.find('.swiper-button-prev').on('click', () => {
			carousel.trigger("previous");
		});

		function setButtons(carousel, $buttonNext, $buttonPrev, $dots) {
			let stageSize = $parentSection.find('.latte-stage').width();
			let contentSize = $parentSection.find('.latte-content').width();
			if (stageSize <= contentSize) {
				$buttonNext.hide();
				$buttonPrev.hide();
				$dots.hide()
				return;
			}

			if ($dots.find('.latte-dot').length === 1) {
				$dots.hide();
			} else {
				$dots.show();
			}

			if (carousel.stage.stageSize === carousel.stage.contentSize + -carousel.stage.currentPosition) {
				$buttonNext.hide();
			} else {
				$buttonNext.show();
			}

			if (-carousel.stage.currentPosition === 0) {
				$buttonPrev.hide();
			} else {
				$buttonPrev.show();
			}
		}
	}
}

