import utils from '../utils';

export default class CookieConsent {
	constructor(analytics, analyticsId) {
		// cookie bar consent
		$('[data-cookie-consent] .cookies-yes').on('click', (e) => {
			e.preventDefault();
			$('[data-cookie-consent]').css({ opacity: 0 });
			setTimeout(() => {
				$('[data-cookie-consent]').addClass('hide');
			}, 600);
			utils.setCookie('cookie-consent', 1);
			return false;
		});

		$('[data-cookie-consent] .cookies-no').on('click', (e) => {
			e.preventDefault();
			$('[data-cookie-consent]').css({ opacity: 0 });
			setTimeout(() => {
				$('[data-cookie-consent]').addClass('hide');
			}, 600);
			utils.setOneDayCookie('cookie-refuse', 1);
			return false;
		});

		if (!utils.getCookie('cookie-consent') && !utils.getCookie('cookie-refuse')) {
			$('[data-cookie-consent]').removeClass('hide');
		} else if (utils.getCookie('cookie-consent')) {
			switch (analytics) {
				case 'ga':
					this.initGA(analyticsId);
					break;
				default:
					break;
			}
		}
	}
	initGA(id) {
		console.log('gid', id);
		window.dataLayer = window.dataLayer || [];

		function gtag() {
			dataLayer.push(arguments);
		}
		gtag('js', new Date());
		gtag('config', id);
	}
}