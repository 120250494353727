import '../scss/index.scss';

import jQuery from 'jquery';
import utils from './utils';
import CookieConsent from './tools/CookieConsent';

import { Carousel } from "latte-carousel";
import stickyfill from 'stickyfilljs';
import smoothscroll from 'smoothscroll-polyfill';

import tippy, { inlinePositioning } from 'tippy.js';
import screenDetector from './screen-detector'; // initializes screensize etc.
import toggleExpand from './tools/toggleExpand';
import Slider from './tools/Slider';

const urlParams = utils.getQueryStringParams(window.location.search);
const login = urlParams.login;

import './nav.js';

import formValidator from './form-validator.js';
import modals from './modals.js';

const page = $('body').data('page');
switch(page) {
	case 'test':
		new Step();
	break;
}

const sidelineExcludedContainerSelectors = 'section.happening, #footNotes, .swiper, [data-image-caption]';

$(document).ready(() => {

	stickyfill.add($('.sticky'));
	smoothscroll.polyfill();

	modals.initModals();
	formValidator.processForms();

	new CookieConsent('ga', gaCode); //gaCode is set in layout.html

	// swipers

	$('.area.resources .swiper, section.happening .swiper, .swiper.proxies').each((index, el) => {
		new Slider(el);
	});

	// open external links in new window
	$('.content-container').find('a[href ^= "http://"], a[href ^= "https://"]').attr('target', '_blank');

	let $footNotes = $('section.footnotes li');

	$('[data-expand-toggle]').on('click', (e) => {
		let $trigger = $(e.currentTarget);
		toggleExpand(e, false, () => {
			const $container = $('.expandable[data-id="' + $trigger.data('target-id') + '"]');
		});
	});

	const carouselOptions = {
		count: 1,
		move: 1,
		touch: true,
		mode: "align",
		buttons: true,
		dots: true,
		rewind: true,
		autoplay: 8000,
		animation: 500,
		responsive: {
			"0": { count: 1, buttons: false },
			"480": { count: 1, buttons: false },
			"768": { count: 1, touch: false },
			"1440": { count: 1, touch: false },
		},
	};

	const carousel = new Carousel(".latte-carousel", carouselOptions);
});