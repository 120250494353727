/* expand/collapse animation */

let t_toggle;

export default function toggleExpand(e, hideTrigger, cb) {
	e.preventDefault()
	const $trigger = $(e.currentTarget);
	const $container = $('.expandable[data-id="' + $trigger.data('target-id') + '"]');
	const $expandable = $container.find('> :first');

	$container.toggleClass('expand');
	$trigger.toggleClass('expanded');

	const expandedState = $container.hasClass('expand');


	if (expandedState) {
		$container.css({
			'height': ($expandable.height()) + 'px'
		});
		//change the height to 'auto' after the transition is complete
		if (t_toggle) {
			clearTimeout(t_toggle);
		}
		t_toggle = setTimeout(() => {
			$container.css({
				height: 'auto',
				'overflow-y': 'visible'
			});
			if (hideTrigger) {
				$trigger.addClass('zeroHeight');
			}
			if (cb) {
				cb();
			}
		}, 401)
	} else {
		//change height back to specific before collapsing so transition will work
		$container.css({ 'height': $container.height() + 'px' });

		//set height back to 0 after sleight delay to allow for the above specific height change to be implemented
		setTimeout(() => $container.css({ 'height': '', 'overflow-y': '' }), 10)
	}
}