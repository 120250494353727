import screenDetector from './screen-detector.js';

(() => {

	window.screenDetector = screenDetector;

	//if (!screenDetector.isSmallScreen()) return;
	const $template = $(`<div class="modal overlay" id="mobile-menu">
		<div class="wrapper">
		</div>
	</div>`);
	let $primary = $('#nav-main .primary').clone();
	$primary.appendTo($template.find('.wrapper'));
	$template.appendTo('body');

	$('#nav-main .trigger-mobile.t_menu, #mobile-menu .close').click( e => {
		//e.preventDefault();
		//$('.top-bar').toggleClass('active');
		$('#mobile-menu').toggleClass('active');
		$('#nav-main .trigger-mobile.t_menu').toggleClass('active');
	});

})();
